const switcherBtn = document.querySelector('.js-theme-switcher');
const faviconLink = document.querySelector('link[rel="icon"][type="image/svg"]');

function handleSwitcherClick(event) {
  const isDarkMode = document.documentElement.hasAttribute('data-theme')
    ? document.documentElement.getAttribute('data-theme') === 'dark'
    : window.matchMedia('(prefers-color-scheme: dark)').matches;
  const newTheme = isDarkMode ? 'light' : 'dark';
  localStorage.setItem('theme', newTheme);
  document.documentElement.setAttribute('data-theme', newTheme);
}

function handleSystemThemeChange(event) {
  if (faviconLink) {
    faviconLink.href = `/favicon.svg?${new Date().getTime()}`;
  }
  localStorage.removeItem('theme');
  document.documentElement.removeAttribute('data-theme');
}

if (switcherBtn) {
  switcherBtn.addEventListener('click', handleSwitcherClick);
}

if (window.matchMedia) {
  const darkThemeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
  const lightThemeMediaQuery = window.matchMedia('(prefers-color-scheme: light)');
  
  if (darkThemeMediaQuery.addListener) {
    darkThemeMediaQuery.addListener(handleSystemThemeChange);
  }
  
  if (lightThemeMediaQuery.addListener) {
    lightThemeMediaQuery.addListener(handleSystemThemeChange);
  }
}